import ApiService from '@/shared/services/api.service';
import Errors from '@/shared/error/errors';
import { AccountsPermissions } from '.././accounts-permissions';

export const ADD_ACCOUNT = 'addAccount';
export const SELECT_ACCOUNT = 'selectAccount';
export const UPDATE_ACCOUNT = 'updateAccount';

export const SET_SELECTED_ACCOUNT = 'setSelectedAccount';

const state = {
    selectedAccount: null,
};

const getters = {
    selectedAccount: state => state.selectedAccount,
    permissions: (state, getters, rootState, rootGetters) => {
        const currentUser = rootGetters['auth/currentUser'];
        return {
            hasPermissionToEdit: new AccountsPermissions(currentUser).edit,
        }
    },
};

const actions = {
    [ADD_ACCOUNT](context, payload) {
        context.commit('shared/activateLoading', 'accounts/addAccount', { root: true });
        return new Promise((resolve) => {
            ApiService.post('users/legal-entity', payload)
                .then(() => {
                    context.dispatch('accounts/list/getAccounts', {}, { root: true });
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'accounts/addAccount', { root: true });
                });
        });
    },
    [SELECT_ACCOUNT](context, payload) {
        context.commit('shared/activateLoading', 'accounts/selectAccount', { root: true });

        return new Promise((resolve) => {
            ApiService.get(`users/${payload.user_id}`)
                .then(({ data }) => {
                    context.commit(SET_SELECTED_ACCOUNT, data);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'accounts/selectAccount', { root: true });
                });
        });
    },
    [UPDATE_ACCOUNT](context, payload) {
        context.commit('shared/activateLoading', 'accounts/updateAccount', { root: true });

        return new Promise((resolve) => {
            ApiService.put(`users/legal-entity/${payload.user_id}`, payload)
                .then(() => {
                    context.dispatch('accounts/list/getAccounts', {}, { root: true });
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'accounts/updateAccount', { root: true });
                });
        });
    },
};

const mutations = {
    [SET_SELECTED_ACCOUNT](state, account) {
        state.selectedAccount = account;
    },
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
