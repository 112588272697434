import ApiService from '@/shared/services/api.service';
import Errors from '@/shared/error/errors';
import { AccountsPermissions } from '.././accounts-permissions';

export const GET_ACCOUNTS = 'getAccounts';
export const ADD_ACCOUNT = 'addAccount';
export const SELECT_ACCOUNT = 'selectAccount';
export const UPDATE_ACCOUNT = 'updateAccount';
export const GET_ROLES = 'getRoles';
export const RESEND_LINK = 'resendLink';

export const SET_ACCOUNTS = 'setAccounts';
export const SET_SELECTED_ACCOUNT = 'setSelectedAccount';
export const SET_ROLES = 'setRoles';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_TOTAL = 'setTotal';
export const SET_FILTERS = 'setFilters';
export const SET_PARAMS = 'setParams';

const state = {
    accounts: [],
    roles: [],
    total: 0,
    currentPage: 1,
    filters: {},
    params: {},
};

const getters = {
    accounts: state => state.accounts,
    total: state => state.total,
    roles: state => state.roles,
    currentPage: state => state.currentPage,
    filters: state => state.filters,
    permissions: (state, getters, rootState, rootGetters) => {
        const currentUser = rootGetters['auth/currentUser'];
        return {
            hasPermissionToEdit: new AccountsPermissions(currentUser).edit,
        }
    },
};

const actions = {
    [GET_ACCOUNTS](context) {
        context.commit('shared/activateLoading', 'accounts/getAccounts', { root: true });

        const params = context.state.params;
        return new Promise((resolve) => {
            ApiService.query('team-users', { params })
                .then(({ data }) => {
                    context.commit(SET_ACCOUNTS, data.data);
                    context.commit(SET_TOTAL, data.pagination.total);
                    resolve(data);
                })
                .catch(error => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'accounts/getAccounts', { root: true });
                });
        });
    },
    [GET_ROLES](context) {
        context.commit('shared/activateLoading', 'accounts/getRoles', { root: true });

        return new Promise((resolve) => {
            ApiService.query('roles', {
                params: {
                    query: `scope eq 'team'`,
                    fields: 'id,name',
                },
            })
                .then(({ data }) => {
                    const roles = data.data;
                    context.commit(SET_ROLES, roles);
                    resolve(roles);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'accounts/getRoles', { root: true });
                });
        });
    },
    [RESEND_LINK](context, payload) {
        context.commit('shared/activateLoading', 'accounts/resendLink', { root: true });

        return new Promise((resolve) => {
            ApiService.post(`users/${payload.user_id}/resend-invitation`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'accounts/resendLink', { root: true });
                });
        });
    },
};

const mutations = {
    [SET_ACCOUNTS](state, accounts) {
        state.accounts = accounts;
    },
    [SET_ROLES](state, roles) {
        state.roles = roles;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_TOTAL](state, total) {
        state.total = total;
    },
    [SET_FILTERS](state, { key, value }) {
        state.filters[key] = value;
    },
    [SET_PARAMS](state, params) {
        state.params = params;
    },
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
