import accountsListStore from '@/modules/accounts/store/accounts-list-store';
import accountsFormStore from '@/modules/accounts/store/accounts-form-store';

export default {
    namespaced: true,

    modules: {
        list: accountsListStore,
        form: accountsFormStore
    },
};
